import React from 'react';
import { getAvgRating } from './utils';
import Rating from '@mui/material/Rating';
const ReviewsStars = props => {
  const { reviews = [] } = props;

  const avgRating = getAvgRating(reviews);

  return <Rating name="read-only" value={avgRating} readOnly />;
};

export default ReviewsStars;
